// AdminPage.js

import React from 'react';
import { Link, Outlet } from 'react-router-dom';
import './AdminPage.css';
import Authenticate from '../components/Authenticate';

const AdminPage = () => {
    return (
        <Authenticate>
            <div className="admin-container">
                <div className="admin-header">
                    <h1>Admin Dashboard</h1>
                </div>
                <nav className="admin-nav">
                    <Link to="/admin/create-event" className="admin-link">Create Event</Link>
                    <Link to="events" className="admin-link">Manage Events</Link>
                    <Link to="users" className="admin-link">Manage Users</Link>
                    <Link to="teams" className="admin-link">Add Teams</Link>
                </nav>
                <Outlet /> {/* Render nested routes here */}
            </div>
        </Authenticate>
    );
};

export default AdminPage;









