import React, { useState, useEffect } from "react";
import { collection, getDocs, query, where, addDoc } from "firebase/firestore";
import db from "../firebase"; 
import './SponsorEvents.css'; 

const SponsorModal = ({ isOpen, onClose, onSubmit, eventType, defaultStartDate }) => {
    const [rewardDescription, setRewardDescription] = useState('');
    const [startDate, setStartDate] = useState(defaultStartDate);
  
    if (!isOpen) return null;
  
    return (
      <div className="sponsor-modal">
        <div className="modal-content">
          <h2>Provide Sponsorship Details</h2>
          <input
            type="text"
            value={rewardDescription}
            onChange={(e) => setRewardDescription(e.target.value)}
            placeholder="Reward Description"
          />
          {eventType === 'place' && (
            <input
              type="date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
            />
          )}
          <button onClick={() => onSubmit(rewardDescription, startDate)}>Submit</button>
          <button onClick={onClose}>Close</button>
        </div>
      </div>
    );
  };
  

const SponsorEvents = () => {
  const [sponsors, setSponsors] = useState([]);
  const [selectedSponsor, setSelectedSponsor] = useState("");
  const [sponsorDetails, setSponsorDetails] = useState({});
  const [eventType, setEventType] = useState("");
  const [events, setEvents] = useState([]);
  const [filters, setFilters] = useState({
    name: '',
    location: '',
    startDate: '',
    endDate: '',
    homeTeam: '',
    awayTeam: ''
  });
// At the top of your SponsorEvents component, add these useState hooks
const [isModalOpen, setIsModalOpen] = useState(false);
const [currentEvent, setCurrentEvent] = useState(null);


  useEffect(() => {
    const fetchSponsors = async () => {
      const querySnapshot = await getDocs(collection(db, "sponsors"));
      const sponsorsData = querySnapshot.docs.map(doc => ({
        id: doc.id,
        name: doc.data().name,
        link: doc.data().link,
        logoURL: doc.data().logoURL
      }));
      setSponsors(sponsorsData);
    };

    fetchSponsors();
  }, []);

  useEffect(() => {
    if (eventType) {
        const fetchEvents = async () => {
          let eventsQuery;
    
          if (eventType === "place") {
            // Exclude events with placeType "Countries" or "US States"
            eventsQuery = query(
              collection(db, "events"),
              where("type", "==", eventType),
              where("placeType", "not-in", ["Countries", "US States"])
            );
          } else {
            // Query for "game" and "activity" event types
            eventsQuery = query(
              collection(db, "events"),
              where("type", "==", eventType)
            );
          }
    
          const querySnapshot = await getDocs(eventsQuery);
          const eventsData = querySnapshot.docs.map(doc => ({
            id: doc.id, // Include the document ID
            ...doc.data()
          }));
          setEvents(eventsData);
        };
    
        fetchEvents();
      }
    }, [eventType]);
    
    const openSponsorModal = (event) => {
        console.log(event)
        setCurrentEvent(event);
        setIsModalOpen(true);
      };
      const handleModalSubmit = async (rewardDescription, startDate) => {
        const expirationDateTime = new Date(startDate);
        expirationDateTime.setDate(expirationDateTime.getDate() + 3); // Add 3 days
    
        const rewardData = {
          eventID: currentEvent.id,
          startDateTime: eventType === 'place' ? new Date(startDate) : new Date(currentEvent.dateTime.seconds * 1000),
          expirationDateTime,
          rewardDescription,
          sponsorID: selectedSponsor
        };
    
        const rewardRef = await addDoc(collection(db, "rewards_test"), rewardData);
        const rewardId = rewardRef.id;
        const sponsorDetails = sponsors.find(s => s.id === selectedSponsor);
        const subCollectionData = {
          rewardID: rewardId,
          expirationDate: expirationDateTime,
          rewardDescription,
          sponsorID: selectedSponsor,
          sponsorLogoURL: sponsorDetails?.logoURL,
          sponsorName: sponsorDetails?.name,
          startDateTime: eventType === 'place' ? new Date(startDate) : new Date(currentEvent.dateTime.seconds * 1000),
        };
    
        // Add the data to the subcollection under the event
        await addDoc(collection(db, `events/${currentEvent.id}/rewards_test`), subCollectionData);
    
        setIsModalOpen(false); // Close modal after submission
      };
    
      const handleModalClose = () => {
        setIsModalOpen(false);
      };
    
      const sponsorButton = (event) => {
        return (
          <button onClick={() => openSponsorModal(event)}>Sponsor</button>
        );
      };

  const handleSponsorChange = (e) => {
    const sponsorId = e.target.value;
    setSelectedSponsor(sponsorId);
    const selectedSponsorDetails = sponsors.find(sponsor => sponsor.id === sponsorId);
    if (selectedSponsorDetails) {
      setSponsorDetails(selectedSponsorDetails);
    } else {
      setSponsorDetails({});
    }
  };

  const handleEventTypeChange = (e) => {
    setEventType(e.target.value);
    setFilters({ // Reset filters when event type changes
      name: '',
      location: '',
      startDate: '',
      endDate: '',
      homeTeam: '',
      awayTeam: ''
    });
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters(prev => ({ ...prev, [name]: value }));
  };

  const applyFilters = () => {
    return events.filter(event => {
      if (filters.name && !event.name.toLowerCase().includes(filters.name.toLowerCase())) {
        return false;
      }
      if (filters.location && !event.locationName.toLowerCase().includes(filters.location.toLowerCase())) {
        return false;
      }
      if (eventType === 'game' || eventType === 'activity') {
        const startDate = filters.startDate ? new Date(filters.startDate) : null;
        const endDate = filters.endDate ? new Date(filters.endDate) : null;
        const eventDate = event.dateTime ? new Date(event.dateTime.seconds * 1000) : null;
  
        if (startDate && eventDate && eventDate < startDate) {
          return false;
        }
        if (endDate && eventDate && eventDate > endDate) {
          return false;
        }
      }
      if (eventType === 'game') {
        if (filters.homeTeam && event.homeTeamID !== filters.homeTeam) {
          return false;
        }
        if (filters.awayTeam && event.awayTeamID !== filters.awayTeam) {
          return false;
        }
      }
      return true;
    });
  };
  
  const renderFilterFields = () => {
    return (
      <div className="filter-fields">
        <input
          name="name"
          value={filters.name}
          onChange={handleFilterChange}
          placeholder="Filter by Name"
        />
        <input
          name="location"
          value={filters.location}
          onChange={handleFilterChange}
          placeholder="Filter by Location"
        />
  
        {(eventType === 'game' || eventType === 'activity') && (
          <>
            <input
              type="date"
              name="startDate"
              value={filters.startDate}
              onChange={handleFilterChange}
              placeholder="Start Date"
            />
            <input
              type="date"
              name="endDate"
              value={filters.endDate}
              onChange={handleFilterChange}
              placeholder="End Date"
            />
          </>
        )}
  
        {eventType === 'game' && (
          <>
            <input
              name="homeTeam"
              value={filters.homeTeam}
              onChange={handleFilterChange}
              placeholder="Home Team"
            />
            <input
              name="awayTeam"
              value={filters.awayTeam}
              onChange={handleFilterChange}
              placeholder="Away Team"
            />
          </>
        )}
      </div>
    );
  };

  const renderTableHeaders = () => {
    switch (eventType) {
      case "game":
        return (<tr><th>Sport Type</th><th>Home Team</th><th>Away Team</th><th>Location</th><th>Date</th><th></th></tr>);
      case "activity":
        return (<tr><th>Name</th><th>Location</th><th>Activity Type</th><th>Date</th><th></th></tr>);
      case "place":
        return (<tr><th>Name</th><th>Location</th><th>Place Type</th><th></th></tr>);
      default:
        return null;
    }
  };
  const renderTableRows = () => {
    const filteredEvents = applyFilters();
    return filteredEvents.map((event, index) => {
      const formatDate = (dateTime) => {
         if (dateTime && dateTime.seconds) {
          return new Date(dateTime.seconds * 1000).toLocaleString();
        }
        return 'N/A';
      };
  
      switch (eventType) {
        case "game":
          return (
            <tr key={index}>
              <td>{event.sportType}</td>
              <td>{event.homeTeamID}</td>
              <td>{event.awayTeamID}</td>
              <td>{event.locationName}</td>
              <td>{formatDate(event.dateTime)}</td>
              <td>{sponsorButton(event)}</td>
            </tr>
          );
        case "activity":
          return (
            <tr key={index}>
              <td>{event.name}</td>
              <td>{event.locationName}</td>
              <td>{event.activityType}</td>
              <td>{formatDate(event.dateTime)}</td>
              <td>{sponsorButton(event)}</td>
            </tr>
          );
        case "place":
          return (
            <tr key={index}>
              <td>{event.name}</td>
              <td>{event.locationName}</td>
              <td>{event.placeType}</td>
              <td>{sponsorButton(event)}</td>
            </tr>
          );
        default:
          return null;
      }
    });
  };




  return (
    <div className="sponsor-events-container">
      <h1 className="page-title">Sponsor Events</h1>
      <div className="sponsor-selection">
        <label htmlFor="sponsorDropdown" className="dropdown-label">Choose a Sponsor:</label>
        <select id="sponsorDropdown" className="dropdown" onChange={handleSponsorChange} value={selectedSponsor}>
          <option value="">Select a Sponsor</option>
          {sponsors.map(sponsor => (
            <option key={sponsor.id} value={sponsor.id}>{sponsor.name}</option>
          ))}
        </select>
      </div>
      {selectedSponsor && (
        <div className="sponsor-details">
          <h2 className="details-title">Details of Selected Sponsor</h2>
          <p className="detail"><strong>Name:</strong> {sponsorDetails.name}</p>
          <p className="detail"><strong>Link:</strong> <a href={sponsorDetails.link} target="_blank" rel="noopener noreferrer">{sponsorDetails.link}</a></p>
          <p className="detail"><strong>Logo URL:</strong> <a href={sponsorDetails.logoURL} target="_blank" rel="noopener noreferrer">{sponsorDetails.logoURL}</a></p>
        </div>
      )}
      <div className="event-type-selection">
        <h2 className="section-title">Find Events to Sponsor</h2>
        <label htmlFor="eventTypeDropdown" className="dropdown-label">Event Type:</label>
        <select id="eventTypeDropdown" className="dropdown" onChange={handleEventTypeChange} value={eventType}>
          <option value="">Select Event Type</option>
          <option value="game">Game</option>
          <option value="activity">Activity</option>
          <option value="place">Place</option>
        </select>
      </div>
      {eventType && renderFilterFields()}

      {eventType && (
        <div className="events-table-container">
          <table className="events-table">
            <thead>{renderTableHeaders()}</thead>
            <tbody>{renderTableRows()}</tbody>
          </table>
        </div>
      )}
    {isModalOpen && (
        <SponsorModal 
          isOpen={isModalOpen}
          onClose={handleModalClose}
          onSubmit={handleModalSubmit}
          eventType={eventType}
          defaultStartDate={new Date().toISOString().split('T')[0]} // current date as default
        />
      )}
    </div>
  );
};

export default SponsorEvents;
