// src/pages/CreateEvent.js
import React, { useState, useEffect, useRef } from "react";
import "./CreateEvent.css";
import {
    collection,
    getDocs,
    addDoc,
    getDoc,
    doc,
    Timestamp,
    GeoPoint,
} from "firebase/firestore";
import db from "../firebase";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ngeohash from "ngeohash";

const CreateEvent = () => {
    const [eventType, setEventType] = useState("game");
    const [isInputRendered, setIsInputRendered] = useState(false);
    const [eventData, setEventData] = useState({
        sportType: "",
        homeTeam: "",
        awayTeam: "",
        checkInRadius: "",
        activityType: "",
        activityName: "",
        locationName: "",
        placeType: "",
        placeName: "", 
    });
    const locationInputRef = useRef(null);
    const [locationDetails, setLocationDetails] = useState({
        locationCoordinates: null,
        locationId: "",
        locationName: "",
    });
    const [dateTime, setDateTime] = useState(new Date());
    const [sportTypes, setSportTypes] = useState([]);
    const [teams, setTeams] = useState([]);
    const [activityTypes, setActivityTypes] = useState([]);
    const [placeTypes, setPlaceTypes] = useState([]);
    //const [isScriptLoaded, setIsScriptLoaded] = useState(false);
    const [showPopup, setShowPopup] = useState(false); 
    const [badgeImage, setBadgeImage] = useState(null);
    const [customPlaceType, setCustomPlaceType] = useState("");
    const [customActivityType, setCustomActivityType] = useState("");

// Fetch Sport Types
const fetchSportTypes = async () => {
    const querySnapshot = await getDocs(collection(db, "sport_type"));
    const sports = querySnapshot.docs.map((doc) => doc.data().sport); // Assuming each doc has a 'name' field
    sports.sort((a, b) => a.localeCompare(b));
    setSportTypes(sports);
};
// Fetch Teams
const fetchTeams = async () => {
    const querySnapshot = await getDocs(collection(db, "teams"));
    const teamData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        sportType: doc.data().sportType,
        teamName: doc.data().teamName,
        imageURL: doc.data().imageURL,
    }));
    teamData.sort((a, b) => a.teamName.localeCompare(b.teamName));  
    setTeams(teamData);
};
// Fetch Activity Types
const fetchActivityTypes = async () => {
    const querySnapshot = await getDocs(
        collection(db, "activity_type")
    );
    const types = querySnapshot.docs.map((doc) => doc.data().type);
    types.sort((a, b) => a.localeCompare(b));
    setActivityTypes(types);
};

const fetchPlaceTypes = async () => {
    const querySnapshot = await getDocs(collection(db, "place_type"));
    const types = querySnapshot.docs.map((doc) => doc.data().type);
    types.sort((a, b) => a.localeCompare(b));
    setPlaceTypes(types);
};

    useEffect(() => {
        

        fetchSportTypes();
        fetchTeams();
        fetchActivityTypes();
        fetchPlaceTypes();
    }, []);
    useEffect(() => {
        // Set isInputRendered to true for both "game" and "activity"
        setIsInputRendered(
            eventType === "game" ||
                eventType === "activity" ||
                eventType === "place"
        );
    }, [eventType]);
    useEffect(() => {
        if (isInputRendered   && locationInputRef.current) {
            const autocomplete = new window.google.maps.places.Autocomplete(
                locationInputRef.current
            );
            autocomplete.addListener("place_changed", () => {
                const place = autocomplete.getPlace();
                if (!place.geometry) {
                    console.log("Returned place contains no geometry");
                    return;
                }

                const lat = place.geometry.location.lat();
                const lng = place.geometry.location.lng();
                const geohash = ngeohash.encode(lat, lng);
                console.log("Place changed:", place);

                setLocationDetails({
                    locationCoordinates: { lat, lng },
                    locationId: place.place_id,
                    geohash,
                });

                setEventData((prevData) => ({
                    ...prevData,
                    locationName: place.name,
                }));
            });
        }
    }, [isInputRendered  ]);

    const isToday = (someDate) => {
        const today = new Date();
        return (
            someDate.getDate() === today.getDate() &&
            someDate.getMonth() === today.getMonth() &&
            someDate.getFullYear() === today.getFullYear()
        );
    };

    const handleEventTypeChange = (e) => {
        setEventType(e.target.value);
        setEventData({
            ...eventData,
            sportType: "",
            homeTeam: "",
            awayTeam: "",
            activityType: "",
            activityName: "",
            placeType: "",
            placeName: "",
        });
    };

    /*const handleInputChange = (e) => {
        setEventData({ ...eventData, [e.target.name]: e.target.value });
    };*/

    const handleInputChange = (e) => {
        const { name, value } = e.target;
    
        // If the input is for checkInRadius, validate the float value
        if (name === "checkInRadius") {
            const floatValue = parseFloat(value);
            // Check if the value is a number and greater than 0.01
            if (!isNaN(floatValue) && floatValue >= 0.01) {
                setEventData({ ...eventData, [name]: value });
            }
            return; // Exit if the condition is not met to avoid updating the state with invalid values
        }
    
        // For other inputs, update the state directly
        setEventData({ ...eventData, [name]: value });
    };
    /*const handleInputChange = (e) => {
        const { name, value } = e.target;
    
        // Check if the input is for checkInRadius and ensure it's a valid decimal
        if (name === "checkInRadius") {
            const floatValue = parseFloat(value);
            // If the value is not a number or less than the minimum, don't update the state
            if (isNaN(floatValue) || floatValue < 0.01) return;
        }
    
        // Update the state for valid inputs
        setEventData({ ...eventData, [name]: value });
    };*/

    const getTeamsForSport = (sportType) => {
        return teams.filter((team) => team.sportType === sportType);
    };

    const Popup = ({ show, onClose }) => {
        if (!show) return null;

        return (
            <div className="popup">
                <div className="popup-inner">
                    <h2>Event Created Successfully!</h2>
                    <button onClick={onClose}>Close</button>
                </div>
            </div>
        );
    };

    const uploadImageToStorage = async (file, path) => {
        if (file.type !== 'image/png') {
            console.error('Only PNG images are allowed');
            return null;
        }
    
        const storage = getStorage();
        const storageRef = ref(storage, `${path}/${file.name}`);
        try {
            await uploadBytes(storageRef, file);
            const downloadURL = await getDownloadURL(storageRef);
            console.log("File URL : downloadURL");
            return downloadURL;
        } catch (error) {
            console.error('Error uploading file:', error);
            return null;
        }
    };

    const createEventGeohash = async (eventId, eventData, geohash) => {
        let collectionName;
        let geohashData;
        console.log('In the events Geohash collection', eventData)
        let imageURL = '';
        let subType = '';
        if (eventData.type === 'place') {
            imageURL = eventData.placeImageURL;
            subType = eventData.placeType;
        } else if (eventData.type === 'activity') {
            imageURL = eventData.activityImageURL;
            subType = eventData.activityType;
        } else if (eventData.type === 'game') {
            try {
                // Fetch the team data from Firestore
                const teamDoc = await getDoc(doc(db, "teams", eventData.homeTeamID));
                subType = eventData.sportType;
                if (teamDoc.exists()) {
                    imageURL = teamDoc.data().imageURL; // Get imageURL from the team data
                } else {
                    console.error("No such team document!");
                    return; // Exit the function if team document doesn't exist
                }
            } catch (e) {
                console.error("Error fetching team document: ", e);
                return; // Exit the function in case of an error
            }
        }

    
        // Check if dateTime exists and set collection name accordingly
        if (eventData.dateTime) {
            const eventDate = eventData.dateTime.toDate(); // Convert Firestore Timestamp to JavaScript Date
            collectionName = isToday(eventDate)
                ? "events_geohashes" //test_collection
                : "future_events_geohashes"; //test_collection
    
            geohashData = {
                dateTime: eventData.dateTime,
                eventID: eventId,
                geohash: geohash,
                locationCoordinates: eventData.locationCoordinates,
                type: eventData.type,
                subType,
                imageURL
            };
        } else if (eventData.type === "place") {
            // For 'place' event type, use a default collection and omit dateTime
            collectionName = "events_geohashes"; //test_collection
            geohashData = {
                eventID: eventId,
                geohash: geohash,
                locationCoordinates: eventData.locationCoordinates,
                type: eventData.type,
                subType,
                imageURL
            };
        } else {
            console.error("Event type not supported or dateTime missing");
            return; // Exit the function if neither condition is met
        }
    
        try {
            await addDoc(collection(db, collectionName), geohashData);
            console.log(`Document created in ${collectionName}`);
        } catch (e) {
            console.error("Error creating geohash document: ", e);
        }
    };
    
    const createBadgeEntriesAlternate = async (
        eventId,
        type,
        badgeURL
    ) => {
        const badgeData = {
            badgeURL: badgeURL,
            eventID: eventId,
            type: type
        }

        try {
            await addDoc(collection(db, "badges"), badgeData); //test_collection
        } catch (e) {
            console.error("Error creating badge document: ", e);
        }
    };

    const createBadgeEntries = async (
        eventId,
        homeTeamId,
        awayTeamId,
        sportType
    ) => {
        const homeTeam = teams.find((team) => team.id === homeTeamId);
        const awayTeam = teams.find((team) => team.id === awayTeamId);

        if (homeTeam && awayTeam) {
            const badgeDataHome = {
                eventID: eventId,
                teamID: homeTeamId,
                type: sportType,
                badgeURL: homeTeam.imageURL,
            };

            const badgeDataAway = {
                eventID: eventId,
                teamID: awayTeamId,
                type: sportType,
                badgeURL: awayTeam.imageURL,
            };
            try {
                await addDoc(collection(db, "badges"), badgeDataHome); //test_collection
                await addDoc(collection(db, "badges"), badgeDataAway); //test_collection
            } catch (e) {
                console.error("Error creating badge document: ", e);
            }
        } else {
            console.error("One or both teams not found for badge creation");
        }
    };
    const resetForm = () => {
        setEventData({
            sportType: "",
            homeTeam: "",
            awayTeam: "",
            checkInRadius: "",
            activityType: "",
            activityName: "",
            locationName: "",
            placeType: "",
            placeName: ""
        });
        setDateTime(new Date()); // Reset date
    };

    const [imagePreviewUrl, setImagePreviewUrl] = useState(""); // State for image preview URL

    // Existing functions...

    const handleImageChange = (e) => {
        const file = e.target.files[0];

        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setImagePreviewUrl(reader.result);
            };
            reader.readAsDataURL(file);
            setBadgeImage(file); // Assuming you have a state setter for the actual file
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        console.log("Event Data on Submit:", eventData);
        console.log("Location Details on Submit:", locationDetails);
        let imageUrl = null;
        if (badgeImage && ['activity', 'place'].includes(eventType)) {
            const path = eventType === 'activity' ? 'activity_images' : 'place_images';
            imageUrl = await uploadImageToStorage(badgeImage, path);
            console.log(imageUrl)
            if (!imageUrl) return; // Stop if image upload fails
        }
        const commonEventData = {
            
            locationCoordinates: new GeoPoint(
                locationDetails.locationCoordinates.lat,
                locationDetails.locationCoordinates.lng
            ),
            locationId: locationDetails.locationId,
            locationName: eventData.locationName,
            checkInRadius: Number(eventData.checkInRadius),
            type: eventType,

        };


            // Check for custom place type and add to Firestore
            if (eventType === "place" && eventData.placeType === "Other" && customPlaceType) {
                // Add the custom place type to Firestore
                try {
                    await addDoc(collection(db, "place_type"), { type: customPlaceType });
                    console.log("New place type added:", customPlaceType);
                } catch (error) {
                    console.error("Error adding custom place type:", error);
                    return; // Exit function if there's an error
                }

                // Set the eventData.placeType to the new custom type
                eventData.placeType = customPlaceType;
            }

            if (eventType === "activity" && eventData.activityType === "Other" && customActivityType) {
                // Add the custom activity type to Firestore
                try {
                    await addDoc(collection(db, "activity_type"), { type: customActivityType });
                    console.log("New activity type added:", customActivityType);
                } catch (error) {
                    console.error("Error adding custom activity type:", error);
                    return; // Exit function if there's an error
                }

                // Set the eventData.activityType to the new custom type
                eventData.activityType = customActivityType;
            }

        if (eventType === "activity") {
            const activityEventData = {
                ...commonEventData,
                dateTime: Timestamp.fromDate(new Date(dateTime)),
                activityImageURL: imageUrl, 
                activityType: eventData.activityType,
                name: eventData.activityName,
            };

            if (Object.values(activityEventData).some(value => value === undefined)) {
                console.error("Some fields are undefined, cannot create event.");
                return; // Exit the function to prevent adding incomplete data
            }
    
            try {
                const docRef = await addDoc(collection(db, "events"), activityEventData); //test_collection
                console.log("Document written with ID: ", docRef.id);
    
                await createEventGeohash(docRef.id, activityEventData, locationDetails.geohash);

                if (docRef.id) {
                    await createBadgeEntriesAlternate(
                        docRef.id,
                        activityEventData.activityType,
                        activityEventData.activityImageURL,
                    );
                }
    
                setShowPopup(true); // Show success popup
                // Reset form values
                resetForm();
                setDateTime(new Date()); // Reset date
            } catch (e) {
                console.error("Error adding document: ", e);
            }
        }

        if (eventType === "place") {
            const placeEventData = {
                ...commonEventData,
                placeImageURL: imageUrl, // Empty string for now
                placeType: eventData.placeType,
                name: eventData.placeName,
            };

            if (Object.values(placeEventData).some(value => value === undefined)) {
                console.error("Some fields are undefined, cannot create event.");
                return; // Exit the function to prevent adding incomplete data
            }
    
            try {
                const docRef = await addDoc(collection(db, "events"), placeEventData); //test_collection
                console.log("Document written with ID: ", docRef.id);
    
                await createEventGeohash(docRef.id, placeEventData, locationDetails.geohash);

                if (docRef.id) {
                    await createBadgeEntriesAlternate(
                        docRef.id,
                        placeEventData.placeType,
                        placeEventData.placeImageURL,
                    );
                }
    
                setShowPopup(true); // Show success popup
                // Reset form values
                resetForm();
            } catch (e) {
                console.error("Error adding document: ", e);
            }
        }

        if (eventType === "game") {
            const completeEventData = {
                awayTeamID: eventData.awayTeam,
                checkInRadius: Number(eventData.checkInRadius),
                dateTime: Timestamp.fromDate(new Date(dateTime)),
                homeTeamID: eventData.homeTeam,
                locationCoordinates: new GeoPoint(
                    locationDetails.locationCoordinates.lat,
                    locationDetails.locationCoordinates.lng
                ),
                locationId: locationDetails.locationId,
                locationName: eventData.locationName,
                sportType: eventData.sportType,
                type: eventType,
            };

            try {
                const docRef = await addDoc(
                    collection(db, "events"), //test_collection
                    completeEventData
                );
                console.log("Document written with ID: ", docRef.id);

                await createEventGeohash(
                    docRef.id,
                    completeEventData,
                    locationDetails.geohash
                );
                if (docRef.id) {
                    await createBadgeEntries(
                        docRef.id,
                        eventData.homeTeam,
                        eventData.awayTeam,
                        eventData.sportType
                    );
                }
                setShowPopup(true);
                // Reset form values
                resetForm();
                setDateTime(new Date()); // Reset date
            } catch (e) {
                console.error("Error adding document: ", e);
            }
        }

        await fetchSportTypes();
        await fetchTeams();
        await fetchActivityTypes();
        await fetchPlaceTypes();
        
    };

    return (
        <div className="create-event-container">
            <h2>Create New Event</h2>
            {showPopup && <Popup show={showPopup} onClose={() => setShowPopup(false)} />} {/* Ensure Popup is conditionally rendered */}
            <form onSubmit={handleSubmit}>
                <div>
                    <label>Event Type:</label>
                    <select
                        name="eventType"
                        onChange={handleEventTypeChange}
                        value={eventType}
                        required
                    >
                        <option value="game">Game</option>
                        <option value="activity">Activity</option>
                        <option value="place">Place</option>
                    </select>
                </div>

                {eventType === "game" && (
                    <div>
                        <div className="form-group">
                            <label>Sport Type:</label>
                            <select
                                name="sportType"
                                value={eventData.sportType}
                                onChange={handleInputChange}
                                required
                            >
                                <option value="">Select Sport Type</option>
                                {sportTypes.map((type) => (
                                    <option key={type} value={type}>
                                        {type}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="form-group">
                            <label>Home Team:</label>
                            <select
                                name="homeTeam"
                                value={eventData.homeTeam}
                                onChange={handleInputChange}
                                required
                            >
                                <option value="">Select Home Team</option>
                                {getTeamsForSport(eventData.sportType).map(
                                    (team) => (
                                        <option
                                            key={team.id}
                                            value={team.id}
                                            disabled={
                                                team.id === eventData.awayTeam
                                            }
                                        >
                                            {team.teamName}
                                        </option>
                                    )
                                )}
                            </select>
                        </div>
                        <div className="form-group">
                            <label>Away Team:</label>
                            <select
                                name="awayTeam"
                                value={eventData.awayTeam}
                                onChange={handleInputChange}
                                required
                            >
                                <option value="">Select Away Team</option>
                                {getTeamsForSport(eventData.sportType).map(
                                    (team) => (
                                        <option
                                            key={team.id}
                                            value={team.id}
                                            disabled={
                                                team.id === eventData.homeTeam
                                            }
                                        >
                                            {team.teamName}
                                        </option>
                                    )
                                )}
                            </select>
                        </div>
                    </div>
                )}

                {eventType === "activity" && (
                    <div>
                        <div className="form-group">
                            <label>Type:</label>
                            <select
                                name="activityType"
                                value={eventData.activityType}
                                onChange={(e) => {
                                    setEventData({ ...eventData, activityType: e.target.value });
                                    if (e.target.value === "Other") {
                                        setCustomActivityType("");
                                    }
                                }}
                                required
                            >
                                <option value="">Select Activity Type</option>
                                {activityTypes.map((type) => (
                                    <option key={type} value={type}>{type}</option>
                                ))}
                                <option value="Other">Other (Create New)</option>
                            </select>
                        </div>
                        {eventData.activityType === "Other" && (
                            <div className="form-group">
                                <label>Custom Type:</label>
                                <input
                                    type="text"
                                    value={customActivityType}
                                    onChange={(e) => setCustomActivityType(e.target.value)}
                                    required
                                />
                            </div>
                        )}
                          <div className="form-group">
                            <label>Name:</label>
                            <input
                                type="text"
                                name="activityName"
                                value={eventData.activityName}
                                onChange={handleInputChange}
                                required
                            />
                        </div>
                    </div>
                )}


                {eventType === "place" && (
                    <div>
                        <div className="form-group">
                            <label>Type:</label>
                            <select
                                name="placeType"
                                value={eventData.placeType}
                                onChange={(e) => {
                                    setEventData({ ...eventData, placeType: e.target.value });
                                    if (e.target.value === "Other") {
                                        setCustomPlaceType("");
                                    }
                                }}
                                required
                            >
                                <option value="">Select Place Type</option>
                                {placeTypes.map((type) => (
                                    <option key={type} value={type}>{type}</option>
                                ))}
                                <option value="Other">Other (Create New)</option>
                            </select>
                        </div>
                        {eventData.placeType === "Other" && (
                            <div className="form-group">
                                <label>Custom Type:</label>
                                <input
                                    type="text"
                                    value={customPlaceType}
                                    onChange={(e) => setCustomPlaceType(e.target.value)}
                                    required
                                />
                            </div>
                        )}
                        <div className="form-group">
                            <label>Name:</label>
                            <input
                                type="text"
                                name="placeName"
                                value={eventData.placeName}
                                onChange={handleInputChange}
                                required
                            />
                        </div>
                        
                    </div>
                )}

                {(eventType === "game" || eventType === "activity") && (
                    <div className="form-group">
                        <label>Date and Time:</label>
                        <DatePicker
                            selected={dateTime}
                            onChange={(date) => setDateTime(date)}
                            showTimeSelect
                            dateFormat="Pp"
                        />
                    </div>
                )}
                <div className="form-group">
                    <label>Event Location:</label>
                    <input
                        ref={locationInputRef}
                        type="text"
                        name="locationName"
                        value={eventData.locationName}
                        onChange={handleInputChange}
                        required
                    />
                </div>
                <div className="form-group">
                    <label>Check-In Radius (miles):</label>
                    <input
                        type="number"
                        name="checkInRadius"
                        value={eventData.checkInRadius}
                        onChange={handleInputChange}
                        min="0.01" // Minimum value set to 0.01 to ensure the value is positive
                        step="0.01" // Allows floating-point numbers. Change to 0.01 to allow increments of 0.01
                        title="Value must be a positive number."
                        required
                    />
                </div>
                {['activity', 'place'].includes(eventType) && (
                    <div className="form-group">
                        <label>Badge Image:</label>
                        <input
                            type="file"
                            accept="image/png"
                            //onChange={(e) => setBadgeImage(e.target.files[0])}
                            onChange={handleImageChange} // Use the updated handler
                            required
                        />
                         {imagePreviewUrl && (
                            <img src={imagePreviewUrl} alt="Image preview" style={{ width: "100px", height: "100px" }} />
                        )}
                    </div>
                )}


                <button type="submit">Create Event</button>
            </form>
        </div>
    );
};

export default CreateEvent;
