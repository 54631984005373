// For Firebase JS SDK v7.20.0 and later, measurementId is optional
import { initializeApp } from 'firebase/app';
import { getFirestore, doc, setDoc } from 'firebase/firestore';
// Add this script to your web pages
import { getAnalytics } from "firebase/analytics";




const firebaseConfig = {
  apiKey: "AIzaSyAYBCXDIjd6pLDsv6zt_YKcvWSoHFOiySs",
  authDomain: "fanily-36ad5.firebaseapp.com",
  projectId: "fanily-36ad5",
  storageBucket: "fanily-36ad5.appspot.com",
  messagingSenderId: "638352044898",
  appId: "1:638352044898:web:c219dd7dd8b49c6c0ab23b",
  measurementId: "G-F3WW7X851W"
};
const app = initializeApp(firebaseConfig);

const db = getFirestore(app);
const analytics = getAnalytics();
//Catherin Changes
//helper function to format date in in YYYY-MM-DD hh:mm:ss format, in UTC
function formatDate(date){
  const month = (date.getUTCMonth() + 1).toString().padStart(2, '0');
  const day = date.getUTCDate().toString().padStart(2, '0');
  const hours = date.getUTCHours().toString().padStart(2, '0');
  const minutes = date.getUTCMinutes().toString().padStart(2, '0');
  const seconds = date.getUTCSeconds().toString().padStart(2, '0');
  return (
    date.getUTCFullYear() + "-" + month + "-" + day + " " + 
    hours + ":" + minutes + ":" + seconds
  );
}

export async function addEmail(email){
  try {
    await setDoc(doc(db, 'emails', email), {
      'timestamp': formatDate(new Date(Date.now()))
    });
    return Promise.resolve("You've successfully joined our mailing list!");
  }
  catch(e){
    return Promise.reject(new Error("Something went wrong when adding the email. Please try again!"));
  }
}

export default db;
