import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAnalytics, logEvent } from "firebase/analytics";

const analytics = getAnalytics();
logEvent(analytics, 'visit_internship_page', {
  page_title: document.title,
  page_url: window.location.href,
});

const Internship = () => {
  useEffect(() => {
    // Replace 'YOUR_GOOGLE_FORM_URL' with your actual Google Form URL
    window.location.replace('https://forms.gle/xwgrGqiJESgj8ydM6');
  }, []);

  return (
    <div>Redirecting...</div>
  );
};

export default Internship;
