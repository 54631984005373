import React, { useState, useEffect } from 'react';
import "./AddTeams.css";
import { collection, getDocs, addDoc } from 'firebase/firestore';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import db from '../firebase';

const AddTeams = () => {
    const [sportTypes, setSportTypes] = useState([]);
    const [selectedSportType, setSelectedSportType] = useState(''); // Added state for selectedSportType
    const [teamName, setTeamName] = useState('');
    const [teamShortName, setTeamShortName] = useState('');
    const [badgeImage, setBadgeImage] = useState(null);
    const [imagePreviewUrl, setImagePreviewUrl] = useState('');
    const [customSportType, setCustomSportType] = useState('');

    useEffect(() => {
        fetchSportTypes();
    }, []);

    const fetchSportTypes = async () => {
        const querySnapshot = await getDocs(collection(db, "sport_type"));
        const sports = querySnapshot.docs.map(doc => doc.data().sport); // Assuming each doc has a 'sport' field
        sports.sort((a, b) => a.localeCompare(b));
        setSportTypes(sports);
    };

    const handleImageChange = e => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setImagePreviewUrl(reader.result);
            };
            reader.readAsDataURL(file);
            setBadgeImage(file);
        }
    };

    const uploadImageToStorage = async (file) => {
        if (!file) return;
        const storage = getStorage();
        const storageRef = ref(storage, `team_badges/${file.name}`);
        await uploadBytes(storageRef, file);
        return await getDownloadURL(storageRef);
    };

    const handleSubmit = async e => {
        e.preventDefault();
        if (!teamName || !teamShortName || !badgeImage) {
            alert('All fields are required!');
            return;
        }
        const badgeUrl = await uploadImageToStorage(badgeImage);
        const sportTypeToUse = customSportType || selectedSportType;
        if (customSportType) {
            // Add new sport type to Firestore
            const docRef = await addDoc(collection(db, "sport_type"), { sport: customSportType });
            // Use the newly added sport type
           // sportTypeToUse = docRef.id;
        }
        // Add new team to Firestore
        await addDoc(collection(db, "teams"), {
            sportType: sportTypeToUse,
            teamName,
            teamShortName,
            badgeUrl
        });
        // Reset form
        setTeamName('');
        setTeamShortName('');
        setBadgeImage(null);
        setImagePreviewUrl('');
        setCustomSportType('');
        setSelectedSportType(''); // Reset selected sport type
        // Optionally, refetch sport types
        fetchSportTypes();
    };

     return (
        <div className="add-teams-container">
            <h2>Add Teams</h2>
            <form onSubmit={handleSubmit}>
                <div className="team-form-group"> 
                    <label>Sport Type:</label>
                    <select
                        value={selectedSportType}
                        onChange={(e) => setSelectedSportType(e.target.value)}
                        required
                    >
                        <option value="">Select Sport Type</option>
                        {sportTypes.map(type => (
                            <option key={type} value={type}>{type}</option>
                        ))}
                        <option value="custom">Add New Sport Type</option>
                    </select>
                    {selectedSportType === 'custom' && (
                        <input
                            type="text"
                            placeholder="Custom Sport Type"
                            value={customSportType}
                            onChange={(e) => setCustomSportType(e.target.value)}
                            required
                        />
                    )}
                </div>
                <div className="team-form-group"> 
                    <label>Team Name:</label>
                    <input
                        type="text"
                        value={teamName}
                        onChange={(e) => setTeamName(e.target.value)}
                        required
                    />
                </div>
                <div className="team-form-group"> 
                    <label>Team Short Name:</label>
                    <input
                        type="text"
                        value={teamShortName}
                        onChange={(e) => setTeamShortName(e.target.value)}
                        required
                    />
                </div>
                <div className="team-form-group"> 
                    <label>Badge Image:</label>
                    <input
                        type="file"
                        accept="image/png"
                        onChange={handleImageChange}
                        required
                    />
                    {imagePreviewUrl && (
                        <img src={imagePreviewUrl} alt="Badge Preview" className="image-preview" /> 
                    )}
                </div>
                <button type="submit" className="team-button">Create Team</button> 
            </form>
        </div>
    );
};

export default AddTeams;
