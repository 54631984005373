// src/components/Authenticate.js

import React, { useState } from 'react';

const Authenticate = ({ children }) => {
    const [password, setPassword] = useState('');
    const [accessGranted, setAccessGranted] = useState(false);

    const correctPassword = 'Phil-nomenal'; // Replace with your actual password

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (password === correctPassword) {
            setAccessGranted(true);
        } else {
            alert('Incorrect password');
        }
    };

    if (!accessGranted) {
        return (
            <div>
                <h3>Admin Access Required</h3>
                <form onSubmit={handleSubmit}>
                    <input
                        type="password"
                        value={password}
                        onChange={handlePasswordChange}
                        placeholder="Enter Password"
                    />
                    <button type="submit">Enter</button>
                </form>
            </div>
        );
    }

    return children;
};

export default Authenticate;
