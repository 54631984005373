import React, { useState, useEffect } from 'react';
import './AdminPage.css';
import { collection, getDocs,  query, where, getDoc, updateDoc, doc, setDoc,deleteDoc} from 'firebase/firestore';
import db from '../firebase'; // Import your Firebase config file

// Modal component for taking actions
const ActionModal = ({ isOpen, onClose, onSubmit, item }) => {
  const [action, setAction] = useState('');
  const [reason, setReason] = useState('');
  

  if (!isOpen) return null;

  const handleSubmit = () => {
    onSubmit(item.id, action, reason);
    onClose();
  };

  return (
    <div className="modal">
      <div className="modal-content">
        <h2>Take Action</h2>
        <select value={action} onChange={(e) => setAction(e.target.value)}>
          <option value="">Select Action</option>
          {/* Add more options as needed */}
          <option value="ban">Ban</option>
          <option value="No Action">No Action</option>
        </select>
        <textarea 
          value={reason} 
          onChange={(e) => setReason(e.target.value)} 
          placeholder="Enter your reasoning"
        />
        <button onClick={handleSubmit}>Submit</button>
        <button onClick={onClose}>Close</button>
      </div>
    </div>
  );
};

const ManageUsers = () => {
    const [activeTab, setActiveTab] = useState('users'); // 'users' or 'posts'
    const [reportedUsers, setReportedUsers] = useState([]);
    const [reportedPosts, setReportedPosts] = useState([]);
    const [modalData, setModalData] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [showReports, setShowReports] = useState({}); // Object to track which report details to show

    const formatDateTime = (firebaseTimestamp) => {
        // Check if firebaseTimestamp is a Firebase Timestamp object
        return firebaseTimestamp?.toDate ? firebaseTimestamp.toDate().toLocaleString() : 'N/A';
      };
  
    useEffect(() => {
      // Fetch reported users
       const fetchReportedUsers = async () => {
        const querySnapshot = await getDocs(collection(db, "reported_users"));
        const reportedUsersData = querySnapshot.docs
          .filter(doc => !doc.data().adminAction)  // Only include docs without adminAction
          .map(doc => ({
            id: doc.id,
            reports: doc.data().reports,
          }));
          console.log("User", reportedUsersData);
        setReportedUsers(reportedUsersData);
      };
    
      // Fetch reported posts and filter out those with admin action
      const fetchReportedPosts = async () => {
        const reportedPostsSnapshot = await getDocs(collection(db, 'reported_posts'));
        const reportedPostsData = await Promise.all(
          reportedPostsSnapshot.docs
            .filter(doc => !doc.data().adminAction)  // Only include docs without adminAction
            .map(async (docSnapshot) => {
              const postData = await getDoc(doc(db, 'posts', docSnapshot.id));
              return {
                id: docSnapshot.id,
                eventID: docSnapshot.data().eventID,
                user: docSnapshot.data().user,
                imageUrl: postData.exists() ? postData.data().imageUrl : null,
                reports: docSnapshot.data().reports,
              };
            })
        );
        console.log("Posts", reportedPostsData);
        setReportedPosts(reportedPostsData);
      };


      fetchReportedUsers();
      fetchReportedPosts();
    }, []);

    const handleToggleReports = (postId) => {
        setShowReports(prev => ({ ...prev, [postId]: !prev[postId] }));
      };

  const openModal = (item) => {
    setModalData(item);
    setIsModalOpen(true);
  };

  const handleActionSubmit = async (id, action, reason) => {
    // Update Firestore document with admin's action and reason
   if(activeTab === 'users') {
    const adminDateTime = new Date();

    // Update Firestore document with admin's action and reason in reported_users
    const reportedUserDocRef = doc(db, "reported_users", id);
    await updateDoc(reportedUserDocRef, {
      adminAction: action,
      adminDateTime: adminDateTime,
      adminComment: reason,
    });
  
    // Check if user exists in reported_users_jail
    const userJailDocRef = doc(db, "reported_users_jail", id);
    const userJailDoc = await getDoc(userJailDocRef);
  
    if (action === "No Action" && userJailDoc.exists()) {
      // Move user from reported_users_jail to users
      const usersDocRef = doc(db, "users", id);
      await setDoc(usersDocRef, { ...userJailDoc.data() });
      await deleteDoc(userJailDocRef);
    } else if (action === "ban") {
      if (userJailDoc.exists()) {
        // Move user from reported_users_jail to reported_users_prison
        const usersPrisonDocRef = doc(db, "reported_users_prison", id);
        await setDoc(usersPrisonDocRef, { ...userJailDoc.data() });
        await deleteDoc(userJailDocRef);
      } else {
        // Move user from users to reported_users_jail
        const usersDocRef = doc(db, "users", id);
        const userDoc = await getDoc(usersDocRef);
        if (userDoc.exists()) {
          const userJailDocRef = doc(db, "reported_users_prison", id);
          await setDoc(userJailDocRef, { ...userDoc.data() });
          await deleteDoc(usersDocRef);
        }
      }
    }

   }

   if(activeTab === 'posts') {
    const adminDateTime = new Date();

    // Update Firestore document with admin's action and reason in reported_users
    const reportedPostDocRef = doc(db, "reported_posts", id);
    await updateDoc(reportedPostDocRef, {
      adminAction: action,
      adminDateTime: adminDateTime,
      adminComment: reason,
    });
  
    // Check if post exists in reported_posts_jail
    const postJailDocRef = doc(db, "reported_posts_jail", id);
    const postJailDoc = await getDoc(postJailDocRef);
  
    if (action === "No Action" && postJailDoc.exists()) {
      // Move post from reported_posts_jail to posts
      const postsDocRef = doc(db, "posts", id);
      await setDoc(postsDocRef, { ...postJailDoc.data() });
      await deleteDoc(postJailDocRef);
    } else if (action === "ban") {
      if (postJailDoc.exists()) {
        // Move post from reported_posts_jail to reported_posts_prison
        const postsPrisonDocRef = doc(db, "reported_posts_prison", id);
        await setDoc(postsPrisonDocRef, { ...postJailDoc.data() });
        await deleteDoc(postJailDocRef);
      } else {
        // Move post from posts to reported_posts_jail
        const postsDocRef = doc(db, "posts", id);
        const postDoc = await getDoc(postsDocRef);
        if (postDoc.exists()) {
          const postJailDocRef = doc(db, "reported_posts_prison", id);
          await setDoc(postJailDocRef, { ...postDoc.data() });
          await deleteDoc(postsDocRef);
        }
      }
    }

   }

  }; 

  const renderTable = (data, type) => (
    <table>
      <thead>
        <tr>
          <th>ID</th>
          {/* Add more headers based on data structure */}
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        {data.map(item => (
          <tr key={item.id}>
            <td>{item.id}</td>
            {/* Render more cells based on data structure */}
            <td>
              <button onClick={() => openModal(item)}>Take Action</button>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );

  const renderReportedUsersTable = () => (
    <table className="reported-users-table">
      <thead>
        <tr>
          <th>Reported User ID</th>
          <th>Reports</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        {reportedUsers.map((user) => (
          <React.Fragment key={user.id}>
            <tr>
              <td>{user.id}</td>
              <td>
                <button onClick={() => handleToggleReports(user.id)}>
                  {showReports[user.id] ? 'Hide Reports' : 'Show Reports'}
                </button>
              </td>
              <td>
                <button onClick={() => openModal(user)}>Take Action</button>
              </td>
            </tr>
            {showReports[user.id] && (
              <tr>
                <td colSpan="3">
                  <table className="detailed-reports-table">
                    <thead>
                      <tr>
                        <th>Comments</th>
                        <th>DateTime</th>
                        <th>ReportedByUser</th>
                      </tr>
                    </thead>
                    <tbody>
                      {user.reports.map((report, index) => (
                        <tr key={index}>
                          <td>{report.comments}</td>
                          <td>{formatDateTime(report.dateTime)}</td>
                          <td>{report.reportedByUser}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </td>
              </tr>
            )}
          </React.Fragment>
        ))}
      </tbody>
    </table>
  );


  return (
    <div className="admin-container">
      <h1>Manage Users and Posts</h1>

      {/* Tab selection */}
      <div className="admin-tabs">
        <button 
          className={`tab-button ${activeTab === 'users' ? 'active' : ''}`} 
          onClick={() => setActiveTab('users')}
        >
          Reported Users
        </button>
        <button 
          className={`tab-button ${activeTab === 'posts' ? 'active' : ''}`} 
          onClick={() => setActiveTab('posts')}
        >
          Reported Posts
        </button>
      </div>

      {/* Conditional rendering based on active tab */}

      {activeTab === 'users' && (
        <div>
          <h2>Reported Users</h2>
          {renderReportedUsersTable()}
        </div>
      )}
      {activeTab === 'posts' && (
  <table className="reported-posts-table">
    <thead>
      <tr>
        <th>Post ID</th>
        <th>Event ID</th>
        <th>Reported User</th>
        <th>Post</th>
        <th>Reports</th>
        <th>Action</th>
      </tr>
    </thead>
    <tbody>
      {reportedPosts.map((post) => (
        <React.Fragment key={post.id}>
          <tr>
            <td>{post.id}</td>
            <td>{post.eventID}</td>
            <td>{post.user}</td>
            <td><a href={post.imageUrl} target="_blank" rel="noreferrer">Post</a></td>
            <td>
              <button onClick={() => handleToggleReports(post.id)}>
                {showReports[post.id] ? 'Hide Reports' : 'Show Reports'}
              </button>
            </td>
            <td>
              {/* Include Action button for each post */}
              <button onClick={() => openModal(post)}>Take Action</button>
            </td>
          </tr>
          {showReports[post.id] && (
            <tr>
              <td colSpan="6">
                <table className="detailed-reports-table">
                  <thead>
                    <tr>
                      <th>Comments</th>
                      <th>DateTime</th>
                      <th>ReportedByUser</th>
                    </tr>
                  </thead>
                  <tbody>
                    {post.reports.map((report, index) => (
                      <tr key={index}>
                        <td>{report.comments}</td>
                        <td>{formatDateTime(report.dateTime)}</td>
                        <td>{report.reportedByUser}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </td>
            </tr>
          )}
        </React.Fragment>
      ))}
    </tbody>
  </table>
)}

      {isModalOpen && (
        <ActionModal 
          isOpen={isModalOpen} 
          onClose={() => setIsModalOpen(false)} 
          onSubmit={handleActionSubmit} 
          item={modalData}
        />
      )}
    </div>
  );
};

export default ManageUsers;