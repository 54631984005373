import React, { useState, useEffect } from 'react';
import db from '../firebase';
import { collection, getDocs, doc, updateDoc } from 'firebase/firestore';
import './ManageEvents.css';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Timestamp } from "firebase/firestore";

function ManageEvents() {
    const [events, setEvents] = useState([]);
    const [currentEvent, setCurrentEvent] = useState({});
    const [showModal, setShowModal] = useState(false);
    const [filter, setFilter] = useState('');

    useEffect(() => {
        const fetchEvents = async () => {
            const eventsCollection = collection(db, 'events');
            const eventsSnapshot = await getDocs(eventsCollection);
            const eventsList = eventsSnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }));
            setEvents(eventsList);
        };
        fetchEvents();
    }, []);

    const openModal = (event) => {
        setCurrentEvent(event);
        setShowModal(true);
    };

    const closeModal = () => {
        setCurrentEvent({});
        setShowModal(false);
    };

    const handleUpdate = async () => {
        if (currentEvent.id) {
            const eventRef = doc(db, 'events', currentEvent.id);
            await updateDoc(eventRef, currentEvent);
            closeModal();
           // Instead of reloading the entire page, update the local state to reflect changes
            setEvents(prevEvents => prevEvents.map(evt => evt.id === currentEvent.id ? { ...evt, ...currentEvent } : evt));

        }
    };

    return (
        <div className="events-container">
            <h2>Manage Events</h2>
             <div className="filter-container">
                <label htmlFor="filter">Filter by Name:</label>
                <input
                    id="filter"
                    type="text"
                    value={filter}
                    onChange={(e) => setFilter(e.target.value)}
                    placeholder="Enter event name"
                />
            </div>
            <table className="events-table">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>DateTime</th>
                        <th>Type</th>
                        <th>SubType</th>
                        <th>Check-In Radius</th>
                        <th>Location Name</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {events
                        .filter(event => event.name && event.name.toLowerCase().includes(filter.toLowerCase()))
                        .map(event => (
                            <tr key={event.id}>
                                <td>{event.name}</td>
                                <td>{event.dateTime?.toDate().toLocaleString()}</td>
                                <td>{event.type}</td>
                                <td>
                                    {event.type === 'place' && event.placeType}
                                    {event.type === 'activity' && event.activityType}
                                    {event.type === 'game' && event.sportType}
                                </td>
                                <td>{event.checkInRadius}</td>
                                <td>{event.locationName}</td>
                                <td>
                                    <button onClick={() => openModal(event)}>Edit</button>
                                </td>
                            </tr>
                        ))
                    }
                </tbody>


            </table>

            {showModal && (
                <div className="popup">
                    <div className="popup-inner">
                        <h3>Edit Event</h3>
                        <form onSubmit={(e) => {
                            e.preventDefault();
                            handleUpdate();
                        }}>
                            <label>
                                Name:
                                <input 
                                    type="text"
                                    value={currentEvent.name || ''} 
                                    onChange={e => setCurrentEvent(prev => ({ ...prev, name: e.target.value }))}
                                />
                            </label>
                            <label>
                                Date and Time:
                                {/* Make sure to include the DatePicker component */}
                                <DatePicker
                                selected={currentEvent.dateTime ? new Date(currentEvent.dateTime.seconds * 1000) : null}
                                onChange={date => setCurrentEvent(prev => ({ ...prev, dateTime: date ? Timestamp.fromDate(date) : null }))}
                                showTimeSelect
                                dateFormat="Pp"
                                isClearable
                                placeholderText="Select a date and time"
                                />
                            </label>
                            <label>
                                Location Name:
                                <input 
                                    type="text"
                                    value={currentEvent.locationName || ''} 
                                    onChange={e => setCurrentEvent(prev => ({ ...prev, locationName: e.target.value }))}
                                />
                            </label>
                            <label>
                                Check-In Radius:
                                <input 
                                    type="number"
                                    value={currentEvent.checkInRadius || ''}
                                    onChange={e => setCurrentEvent(prev => ({ ...prev, checkInRadius: parseFloat(e.target.value) }))}
                                    min="0.01"
                                    step="0.01"
                                />
                            </label>
                            <div className="buttons-container">
                                <button type="submit" className="update-button">Update</button>
                                <button type="button" className="cancel-button" onClick={closeModal}>Cancel</button>
                            </div>
                        </form>
                    </div>
                </div>
            )}

        </div>
    );
}

export default ManageEvents;
